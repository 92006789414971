<!--能源设置--趋势组组---->
<template>
  <div id="timeTrendGr" v-loading="loading" :element-loading-text="$t('alarm.a0')"> 
    <div class="header-title">
      <!-- {{ $t("energy.setting.reportGr.timeTrendGr.name") }} -->
       {{ $t("energy.enedata540") }}
    </div>
    <div style="display: flex;width:100%;height:100%;">
      <div class="side">
        <div class="mg">
          <div class="mg-add">
            <a-icon type="plus-circle" style="vertical-align:10px;" @click="addClick" :title="addTitle"/>
          </div> 
        </div>

        <div class="side-menu">
          <a-menu
            style="width: 256px"
            mode="inline"
            :inline-collapsed="collapsed"
            :open-keys.sync="openKeys"
            @click="handleClick"
            v-model="selectedKeys"
          >
            <template v-for="item in rptGroupList">
              <a-menu-item v-if="!item.children" :key="item.no">
                <!-- <a-icon type="pie-chart" /> -->
                <a-icon type="google" />
                <span>{{ item.text }}</span>
              </a-menu-item>
              <sub-menu v-else :key="item.no" :menu-info="item" :fatherMethod="deleteClick"/>
            </template>
            
          </a-menu>
        </div>
        
      </div>

      <div class="main" ref="main" v-if="rptGroupId==0">
          <a-empty>
            <span slot="description"> {{ $t("energy.setting.reportGr.empty") }} </span>
          </a-empty>
      </div>
      <div class="main" ref="main" v-else>
      
        <div>
          <div class="rptGroup"  style="">
            <div class="rptGroupName" style="">
              <span>{{ $t("energy.setting.reportGr.grlabel") }}：</span><span>{{rptGroup.reportGroupName}}</span>
            </div>
            <a-button  shape="circle" icon="edit" size="small" style="margin-top:5px;" @click="updateClick" :disabled="rptGroupId==0"  />
          </div>
          <div class="rptGroupPoint">
            <ene-rpt-table :data="rptGroup.rptGroupList" :Yscroll="Yscroll"></ene-rpt-table>
          </div>
          <div>

            <div class="btn-display button">
                <a-button type="primary" @click="editReportGroupPoint">{{ $t("energy.setting.reportGr.timeTrendGr.edit") }}</a-button>
              </div>
          </div>
        </div>
 
      </div>

    <div>
        <a-modal v-model="visible" :title="name" @ok="handleOk" :width='600'>
          <div v-loading="frmloading" :element-loading-text="$t('alarm.a0')">
          <a-form-model ref="timeTrendGrForm" 
              :model="form" 
              :rules="rules"
              :label-col="formItemLayout.labelCol" 
              :wrapper-col="formItemLayout.wrapperCol"  >
                  
              <a-form-model-item  ref="name" prop="name">
                    <span slot="label">
                    {{ $t("energy.setting.reportGr.timeTrendGr.nameLabel") }}&nbsp;
                      <a-tooltip :title='tooltip'>
                      <a-icon type="question-circle-o" />
                      </a-tooltip>
                  </span>
                  <a-input 
                      v-model="form.name"
                      @blur="
                      () => {
                          $refs.name.onFieldBlur();
                      }
                      "
                      :placeholder="inputLabel"
                  />
              </a-form-model-item>
                  
          </a-form-model>
          </div>
        </a-modal>
      </div>
      <ene-rpt-point :title="pointSelectTitle" :rptGroupId="rptGroupId" :rptGroupName="rptGroup.reportGroupName" :lmKind="lmKind" :modeno="modeno"> </ene-rpt-point>
    </div>
  </div>
</template>

<script>
import { getEneReportGrList , getEneReportGrDetail } from "../../../api/energy";
import SubMenu from "../components/measureGroup/eneMeasureGroupSubMenu.vue";
import EneReportGroupPointTable from "../components/measurePoint/eneReportGroupPointTable.vue";
import SEneReportGroupPoint from "../components/measurePoint/eneBuildReportGroupPoints.vue";
const formItemLayout = {
  labelCol: {
            xs: { span: 24 },
            sm: { span: 10 },
        },
  wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
  },
};
const formTailLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 8, offset: 4 },
};

export default {
  components: {
    'sub-menu': SubMenu,
    'ene-rpt-table':EneReportGroupPointTable,
    'ene-rpt-point':SEneReportGroupPoint,
  },
  data() {
      let name = this.$t("energy.setting.reportGr.timeTrendGr.name");
      let addTitle  = this.$t("energy.add") + name;
      let spLabel = this.$t("energy.setting.reportGr.spLabel").replace('${label}',name);
      let lenLabel = this.$t("energy.setting.reportGr.lenLabel").replace('${label}',name).replace('${min}',1).replace('${max}',30);
      let inputLabel = this.$t("energy.setting.reportGr.inputLabel");
      console.log('inputLabel>>',inputLabel);
      inputLabel = inputLabel.replace('${label}',name);
      console.log('inputLabel2>>',inputLabel);
      let tooltip =this.$t("energy.setting.reportGr.stop")+' [ ` ~ ! @ # $ % ^ & *()_+<>?: " { } , . \ / ;  [ \ ] ] ·！#￥（——）：；“”‘、，|《。》？、【】';
      let normalName = (rule, value, callback) => {
          let regEn = /[`~!@#$%^&*+<>?:"{},\/;'[\]]/im;
          if(regEn.test(value)) {
              callback(new Error(spLabel));
          } else {
              callback();
          }
      };
    
      return {
        name:name,
        loading:false,
        frmloading:false,
        selectedKeys:[],
        rptGroupList:[],
        rptGroupId:0,
        form:{
            id:0,
            name: "",
        },
        rptGroup:{
            reportGroupName: "",
            reportGroupNo: 0,
            reportType: 1,
            rptGroupList: [],
        },
        Yscroll:{y:400},
        openKeys: ["0"],
        visible:false,
        dataChange:false,
        selChange:false,
        collapsed: false,
        formItemLayout,
        formTailLayout,
        rules: {
            name: [
                { required: true, message: inputLabel, trigger: 'blur' },
                { min: 1, max: 30, message: lenLabel, trigger: 'blur' },
                { validator:normalName, trigger: 'blur' },
            ]
        },
        pointSelectVisible:false,
        pointSelectTitle:this.$t("energy.setting.reportGr.timeTrendGr.edit"),
        modeno:20,
        lmKind:3,
        reportGroupType:5,
        addTitle:addTitle,
        tooltip:tooltip,
        inputLabel:inputLabel,
        spLabel:spLabel,
        lenLabel:lenLabel,
      };
  },
  activated(){
    console.log("activated",this.$store.getters.clientId);
    this.selectedKeys = [];
    this.rptGroupList = [];
    this.rptGroupId = 0;
    this.rptGroup={
        reportGroupName: "",
        reportGroupNo: 0,
        reportType: 1,
        rptGroupList: [],
    };
        
    if(this.$store.getters.clientId){
        console.log("activated load data");
        this.loadReportGrList(0);
    }
  },
  mounted() { 
    // 调用表格高度计算方法
    this.tableSize();
    // 根据窗口变化计算表格高度
    window.addEventListener("resize", this.tableSize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.tableSize);
  },
  computed: {
    
  },
  watch: {
    openKeys(val) {
      //console.log('openKeys', val);
    },
    "$store.getters.clientId":{
      handler(val){
        //console.log(val);
        if(val!==""){
          this.loadReportGrList(0);
        }
      },
      deep:true,
      immediate:true,
    }
  },
  methods: {
      // 表格高度计算
      tableSize() {
          setTimeout(() => {
              let height = this.$refs.main.clientHeight;
              let tableHeight = height - 130;
              this.Yscroll.y = tableHeight;
          }, 0);
          
      },
      editReportGroupPoint(){
        this.pointSelectVisible = true;
      },
      handleClick(e) {
          //console.log('handleClick', e);
          this.rptGroupId = parseInt(e.key);
          if(parseInt(this.rptGroupId)>0){
            this.selChange = true;
          }
          this.loadReportGrDetail();
      },
      deleteClick(e,no,text) {
          //console.log('deleteClick', e,no,text);
          
          if(this.rptGroupId==no){
            this.rptGroupId = 0;
          }
          let title = this.$t("energy.setting.reportGr.deleLabel").replace('${name}',text).replace('${label}',this.name);
          this.$confirm({
          title: title,
          centered: true,
          onOk: () => {
                this.loading = true;
                let params = {
                  action:2,
                  clientId: this.$store.getters.clientId,
                  siteId: this.$route.query.id,
                  sitegrId: this.$store.getters.sitegrId,
                  tenantId: 0,
                  reportGroupType: this.reportGroupType,
                  reportGroupNo: no,
                };
              console.log('DeletetimeTrendGr',params);
              getEneReportGrList(params)
                .then((res) => {
                  console.log("DeletetimeTrendGr Result",res);
                
                  if(res.errorCode == '00' || res.errorCode == '05'){
                    this.$message.success(res.msg);
                    this.loadReportGrList(0);
                  }else{
                    this.$message.error(res.msg);
                    this.loading = false;
                  }      
                })
                .catch((err) => {
                  this.loading = false;
                  console.log(err);
                });
              }
          });
      },
      handleOk(e) {
          this.$refs.timeTrendGrForm.validate(valid => {
              if (valid) {
                  this.saveReportGroup(this.form.id,this.form.name);
              } else {
                  console.log('error submit!!');
                  return false;
              }
          });
      },
      saveReportGroup(no,text){
          let title = this.$t("energy.setting.reportGr.saveLabel");
          title = title.replace('${label}',this.name);
          title = title.replace('${name}',text);
          //console.log('saveReportGroup2',title);
          this.$confirm({
              title: title,
              centered: true,
              onOk: () => {
                  this.frmloading = true;
                  let params = {
                      action:1,
                      clientId: this.$store.getters.clientId,
                      siteId: this.$route.query.id,
                      sitegrId: this.$store.getters.sitegrId,
                      tenantId: 0,
                      reportGroupType: this.reportGroupType,
                      reportGroupName: text,
                      reportGroupNo: no,
                  }
                  //console.log("SaveReportGroup Result",params);
                  getEneReportGrList(params)
                  .then((res) => {
                      //console.log("SaveReportGroup Result",res);
                      this.frmloading = false;

                      if(res && (res.errorCode == '00' || res.errorCode == '03')){
                          this.$message.success(this.$t("energy.setting.reportGr.saveSuce"));
                          if(parseInt(no)>0){
                            this.rptGroup.reportGroupName = text;
                          }
                          this.loadReportGrList(0);
                          this.visible = false; 
                      }else{
                          this.$message.error(this.$t("energy.setting.reportGr.saveFail"));
                      }      
                  })
                  .catch((err) => {
                      this.frmloading = false;
                      console.log(err);
                  });
              }
          });
      },
      addClick(e) {
        console.log('addClick', e);
        if(this.$refs.timeTrendGrForm){
          this.$refs.timeTrendGrForm.resetFields();
        }
        this.form.name = '';
        this.form.id = 0;
        this.visible = true;
        
      },
      updateClick() {
        console.log('updateClick', this.form);
        if(this.$refs.timeTrendGrForm){
          this.$refs.timeTrendGrForm.resetFields();
        }
        this.form.name = this.rptGroup.reportGroupName;
        this.form.id = this.rptGroup.reportGroupNo;
        this.visible = true;
      },
      loadReportGrDetail() {
          if(parseInt(this.rptGroupId)>0){
              let params =  {
                  action:0,
                  clientId: this.$store.getters.clientId,
                  siteId: this.$route.query.id,
                  sitegrId: this.$store.getters.sitegrId,
                  tenantId: 0,
                  reportGroupType: this.reportGroupType,
                  reportGroupNo: this.rptGroupId,
              };
              console.log("loadMeasureDetail Params",params);
              this.loading = true;
              getEneReportGrDetail(params)
              .then((res) => {
                  console.log("loadMeasureDetail Result",res);
                  this.loading = false;
                  if(res && res.errorCode == '00'){
                      this.rptGroup = res.data;
                  } else {
                      this.$message.error(this.$t("energy.setting.reportGr.loadFail"));
                  }

              })
              .catch((err) => {
                  this.loading = false;
                  console.log(err);
              });
          }
      },
      loadReportGrList(action){
        let params =  {
            action:action,
            clientId: this.$store.getters.clientId,
            siteId: this.$route.query.id,
            sitegrId: this.$store.getters.sitegrId,
            tenantId: 0,
            reportGroupType: this.reportGroupType,
        };
        console.log("loadReportGrList Params",params);
        this.loading = true;
        getEneReportGrList(params)
        .then((res) => {
          console.log("loadReportGrList Result",res);

          if(res && res.errorCode == '00'){
              this.rptGroupList = res.data.rptGroupList;
          } else {
              this.$message.error(this.$t("energy.setting.reportGr.loadFail"));
          }     
          this.loading = false;
        })
        .catch((err) => {
            this.loading = false;
            console.log(err);
        });
      },

  }
};
</script>

<style scoped>
#timeTrendGr {
  width: 100%;
  height: 100%;
  padding: 10px 20px;
  font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
ul, li {
  list-style: none;
  margin: 0;
}
.header-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 18px;
}
.header-title::before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 30px;
  background: #7585cd;
  margin-right: 20px;
}
.side {
  width: 280px;
  height: calc(100% - 24px);
  box-shadow: 0px 3px 8px #e7e6e6;
  position: relative;
}
.side-menu{
  max-height:  calc(100% - 50px);
  overflow: auto;
}
.main {
  width: 100%;
  height: calc(100% - 24px);
  padding: 5px;
  box-shadow: 0px 3px 8px #e7e6e6;
}
.mg{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 10px;
}
.mg-add{
  width: 24px;
  height: 24px;
  border-radius: 24px;
  font-size: 22px;
  color: #7682ce;
  border:1px solid #FFFFFF;
  margin: 3px;
}
.mg-add:hover{
  color: #FFFFFF;
  background-color: #7682ce;
  border:1px solid #7682ce;
 
}
.rptGroup{display: flex; justify-content: flex-start;}
.rptGroupName{margin:3px;padding:3px;font-size:14px;line-height:24px;}
.rptGroupPoint{width: 100%;height: calc(100% - 100px);}
.btn-display{
  display: flex; justify-content: flex-end;
}
.button{
  padding: 10px 0;
}
</style>